export const stripHtmlTagsAndH3Content = (str: string) =>
  str
    // remove all h3 tags alongside their content
    .replace(/<h3\b[^>]*>[\s\S]*?<\/h3>/gi, '')
    // Remove other HTML tags
    .replace(/<[^>]*>/g, '')
    // Remove extra white spaces
    .trim()
    .slice(0, 200)

interface ISeoTagsFields {
  title?: string
  description?: string
  // url of the page
  url: string
  keywords?: string[]
  image?: string
}

export const generateBasicSeoTags = ({ title, description, url, keywords = [], image }: ISeoTagsFields) => {
  const defaultTitle = "Join Seaplify - The maritime community that empowers tomorrow's maritime professionals."
  const defaultDescription =
    'Seaplify provides the tools, resources, and opportunities to create a more connected, equitable, and prosperous future for all maritime industry professionals.'
  const defaultKeywords = keywords.length
    ? keywords.join(', ')
    : 'Seafarers, maritime, jobs, community, networking, news, shipping industry'
  const defaultImage = 'https://seaplify.com/branding/og-image.png'

  return (
    <>
      <meta name="description" content={description || defaultDescription} key="description" />
      <meta property="og:description" content={description || defaultDescription} />

      {keywords.length && <meta name="keywords" content={defaultKeywords} key="keywords" />}

      {/* Open Graph tags */}
      <meta property="og:url" content={url} />
      <meta name="twitter:site" content={url} />

      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:type" content="website" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@seaplify.com" />

      <meta property="og:title" content={title || defaultTitle} />
      <title>{title || defaultTitle}</title>
    </>
  )
}
